div:focus-within {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  user-select: none;
}

.reports_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .report_select_div {
    padding: 10px;
  }
}

.custom-timeline {
  margin-left: 20px;

  .rs-timeline-item-custom-dot {
    .rs-icon {
      position: absolute;
      background: #fff;
      top: 0;
      left: -2px;
      border: 2px solid #ddd;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 18px;
      color: #999;
      margin-left: -13px;
      justify-content: center;
      padding: 8px;
    }
  }

  .rs-timeline-item-content {
    margin-left: 24px;
  }
}

.rs-timeline-item-content p {
  margin: 0;
  font-size: 14px;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.w-30 {
  width: 30px !important;
}

.report-card-fix-height {
  min-height: 350px;
  max-height: 350px;
  overflow: scroll;
}
.w-340 {
  width: 340px;
}

.ms-5 {
  margin-left: 3px !important;
}
.me-5 {
  margin-right: 3px !important;
}

.w-100 {
  width: 100%;
}

.z-10 {
  z-index: 9900 !important;
}
@import "./ControlledInputField.scss";
@import "./ErrorAlert.scss";
