.error-alert-wrapper {
    position: absolute;
    top: 20px;
    left: auto;
    right: auto;
    width: 350px;
    font-size: 15px !important;

    .error-progress {
        position: relative;
        margin-top: -20px;
        margin-right: 6px;
        margin-left: 6px;
    }
}